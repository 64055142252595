.login {
  .card {
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    padding: 32px 40px;
    width: 400px;

    &::after,
    &:before {
      content: "";
      background-image: url(../../../../public/assets/images/login-bg.png);
      background-size: contain;
      height: 10.625rem;
      height: 14.625rem;
      position: absolute;
      width: 10.625rem;
      width: 14.625rem;
      z-index: -1;
    }

    &::after {
      left: -7.32rem;
      top: -7.32rem;
    }

    &:before {
      bottom: -7.32rem;
      right: -7.32rem;
    }
  }

  .resend {
    margin-top: 15px;
  }
}

.security-code {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .form-control {
    width: 42px;
    border: 2px solid #DFE1E6;

    &:focus {
      border: 2px solid #7367f0;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
    }

  }
}