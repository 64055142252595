$primary: #2003ff;
$secondary: #595561;
$success: #008000;
$info: #00b5b4;
// $warning: #000000;
$danger: #fa3434;
$light: #e9ecef;
$dark: #000;
$dark-opacity-068: rgba(51, 48, 60, 0.68);
$body: #f6f6f7;
$border-color: rgba(0, 0, 0, 0.25);

@import "bootstrap/scss/bootstrap.scss";
@import "./button-loading.scss";
@import "./form-control.scss";
@import "./loading.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

body {
	background: #f6f6f7;
	font-size: 14px;
	font-weight: 400;
	color: #000;
	font-family: "Poppins";
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	color: #000;
}

h2 {
	// 28px
	font-size: 1.75rem;
	font-weight: 400;
	line-height: 1.938rem;
	color: #000;
}

h3 {
	// 22px
	font-size: 1.375rem;
	font-weight: 600;
	line-height: 1.312rem;
	letter-spacing: 0em;
}

h4 {
	// 18px
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.125rem;
}

.break-spaces {
	white-space: break-spaces;
}

.Toastify__close-button--light {
	color: white;
	opacity: 100% !important;
}

.main-footer {
	border-top: 1px solid $border-color;
	background-color: #f6f6f7;
	position: sticky;
	bottom: 0;
	z-index: 9;

	ul {
		li {
			.active {
				color: $primary;
			}
		}
	}
}

// Image

.drag-drop {
	label {
		color: rgba(51, 48, 60, 0.87);
		font-weight: 300;
		line-height: 0.9375rem;
	}

	.dropzone {
		padding: 10px;
		border: 1px blue dashed;
		border-radius: 0.4rem;
		// margin: auto;

		p {
			font-size: 12px;
		}
	}
}

.MyDropzone {
	.product-drag-drop {
		cursor: pointer;
	}

	.MyDropzone-2 {
		.product-drag-drop {
			height: 108px;
		}
	}
}

.product-drag-drop button.btn-close {
	position: absolute;
	margin-left: -18px;
	top: 3px;
	width: 2px;
}

.input-group-text {
	width: fit-content;
}

// Other

.btn {
	align-items: center;
	border-radius: 5px;
	display: flex;
	font-size: 0.9375rem;
	font-weight: 500;
	justify-content: center;
	letter-spacing: 0.0268rem;
	line-height: 1.125rem;
	padding: 0.625rem 1.25rem;
	text-transform: uppercase;
	width: fit-content;

	img,
	svg {
		margin-right: 0.5rem;
	}

	&:focus-visible {
		box-shadow: none;
	}

	.btn-text {
		display: flex;
		align-items: center;
	}

	&.btn-link {
		color: $primary;
		text-decoration: none;
		text-transform: none;
		font-weight: 400;
	}

	&.btn-primary {
		white-space: nowrap;
		background: $primary;
		color: #ffffff;

		img {
			margin-left: 8px;
		}

		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
				$primary;
		}
	}

	// &.btn-white {
	//   border: 1px solid #dee2e6;
	//   color: #848B9C;
	//   white-space: nowrap;

	//   img {
	//     margin-right: 8px;
	//   }
	// }
}

.show-password {
	right: 0;
	position: absolute;
	top: 42px;
}

.avtar {
	height: 36px;
	width: 36px;
	// padding-top: 5px;
	line-height: 36px;
	text-align: center;
}

.pos-justify {
	display: flex;

	&.pos-start {
		justify-content: start;
	}

	&.pos-center {
		justify-content: center;
	}

	&.pos-between {
		justify-content: space-between;
	}

	&.pos-end {
		justify-content: end;
	}
}

.pos-align {
	display: flex;

	&.pos-start {
		align-items: start;
	}

	&.pos-center {
		align-items: center;
	}
}

// .mb-3 {
//   margin-bottom: 1.25rem !important;
// }

.mb-md-0 {
	margin-bottom: 0 !important;
}

.react-datepicker-wrapper {
	display: block;
	padding: 0;
	border: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	// width: 100%;
}

.campaigndatepicker {
	position: relative;

	.react-datepicker__input-container input::placeholder {
		font-weight: 300 !important;
		color: rgba(51, 48, 60, 0.4) !important;
		opacity: 0.4;
	}
}

.fc-event-main {
	padding: 8px;
	padding-bottom: 6px;
}

.card {
	a {
		// 16px
		font-size: 1rem;
	}

	.card-header {
		.loader {
			position: absolute;
			top: -28px;
			right: 0;
			left: 0;
			margin: auto;
		}
	}

	.consolidated-tasks {
		.consolidated-tasks-list {
			width: 100%;
		}

		&.both {
			.consolidated-tasks-list {
				width: 49%;
			}
		}
	}
}

header {
	.back-button {
		width: 30px;

		svg {
			margin: 0 !important;
			width: 20px;
			height: 20px;
		}
	}
}

.tabs {
	align-items: center;
	display: flex;
	gap: 0.3125rem;
	margin-bottom: 1.25rem;
	justify-content: space-evenly;

	@media only screen and (max-width: 1369px) {
		justify-content: space-between;
	}

	.nav-item {
		.btn-link {
			color: rgba(51, 48, 60, 0.6);
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.1875rem;
			min-width: 13.75rem;
			text-transform: uppercase;

			@media only screen and (max-width: 1369px) {
				min-width: 11rem;
			}

			@media only screen and (max-width: 1104px) {
				min-width: 8rem;
			}

			@media only screen and (max-width: 834px) {
				min-width: 7rem;
				font-size: 0.875rem;
			}

			&.active {
				color: #fff;
				background-color: #2003ff;
			}
		}
	}
}

.custom-calander {
	.fc-media-screen {
		position: relative;
		z-index: 0;
	}
}