.to-do-list-section {
	// height: calc(100vh - 90px);

	.card {
		.card-body {
			max-height: calc(100vh - 240px);

			&.consolidated-tasks {
				max-height: calc(100vh - 240px);
			}
		}

		li {
			.avtar-logo {
				font-size: 8px;
				padding-top: 1px;

			}

			.to-do-sub-heading {
				font-size: 10px;

				a {
					font-size: 10px;
				}
			}
		}
	}

	.dropdown-center {
		// width: 240px;
		float: right;

		.form-group {
			margin-bottom: 0px !important;

			.react-select__menu {
				width: 300px;
				right: 0;
			}
		}
	}
}



.task-section {
	.card {
		.card-body {
			// height: calc(100vh - 313px);
		}

		li {
			.avtar-logo {
				font-size: 8px;
				padding-top: 1px;

			}

			.to-do-sub-heading {
				font-size: 10px;
			}
		}
	}

	.dropdown-center {
		.form-group {
			margin-bottom: 0px !important;
		}
	}
}

button.fc-today-button.fc-button.fc-button-primary {
	text-transform: capitalize;
}

.fc-event-main {

	a.text-danger {
		width: 50px !important;
		white-space: break-spaces;
	}
}