header {
	background-color: #fff;
	padding: 15px 0px;

	.nav {
		.nav-item {
			.nav-link {
				font-size: 16px;
				color: #000;
			}

			&.active {
				.nav-link {
					text-decoration: underline;
					text-underline-offset: 5px;
				}
			}
		}
	}
}